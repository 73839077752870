import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { Cell, Text } from '@vinted/web-ui'

import styles from './FeedbackBanner.module.css'

const FeedbackBanner = () => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.wrapper}>
      <Link href="feedback" target="_blank" rel="noopener noreferrer" prefetch={false}>
        <Cell
          clickable
          theme="transparent"
          type={Cell.Type.Navigating}
          styling={Cell.Styling.Narrow}
        >
          <Text
            text={t('feedbackBanner.leaveFeedback')}
            theme="inverse"
            type={Text.Type.Subtitle}
          />
        </Cell>
      </Link>
    </div>
  )
}

export default FeedbackBanner
