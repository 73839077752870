import { useContext, useCallback, ReactNode, createContext, useMemo } from 'react'

import { useCookies } from 'lib/context/cookie'

import { TrackingEvent } from './types'

type TrackingContextState = {
  track: (event: TrackingEvent) => void
}

const INITIAL_TRACKING_CONTEXT_STATE: TrackingContextState = {
  /* eslint-disable @typescript-eslint/no-empty-function */
  track: () => {},
}

const TrackingContext = createContext<TrackingContextState>(INITIAL_TRACKING_CONTEXT_STATE)

const TrackingProvider = (props: { children: ReactNode }) => {
  const {
    cookies: { generalConsent },
  } = useCookies()

  const track = useCallback(
    ({ event, params }: TrackingEvent) => {
      if (generalConsent !== 'granted') return

      gtag('event', event, params)
    },
    [generalConsent],
  )

  const value = useMemo(() => ({ track }), [track])

  return <TrackingContext.Provider value={value}>{props.children}</TrackingContext.Provider>
}

const useTracking = () => {
  const { track } = useContext(TrackingContext)

  return useMemo(() => ({ track }), [track])
}

export { useTracking, TrackingContext, TrackingProvider }
