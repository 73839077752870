import { useMemo } from 'react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'

import { FALLBACK_LOCALE } from 'lib/constants/locale'
import { VINTED_GO_LANDING_PAGE_URL } from 'lib/constants/link'

const useVintedGoCorporatePageUrl = (path?: `/${string}`) => {
  const router = useRouter()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { i18n } = getConfig()

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const locale = `${router.locale || i18n?.defaultLocale || FALLBACK_LOCALE}`

  return useMemo(() => {
    return `${VINTED_GO_LANDING_PAGE_URL}/${locale}${path || ''}`
  }, [path, locale])
}

export default useVintedGoCorporatePageUrl
