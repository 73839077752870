import { ReactNode } from 'react'
import Head from 'next/head'
import classNames from 'classnames'

import Footer from 'components/Footer'
import Header from 'components/Header'
import CookieBanner from 'components/CookieBanner'
import FeedbackBanner from 'components/FeedbackBanner'

import { LayoutTheme, LayoutContentWidth, LayoutHeaderPosition } from 'lib/types/layout'

import styles from './Layout.module.css'

type Props = {
  theme?: LayoutTheme
  contentWidth?: LayoutContentWidth
  headerPosition?: LayoutHeaderPosition
  children: ReactNode
}

const Layout = ({
  children,
  theme = 'default',
  contentWidth = 'default',
  headerPosition = 'sticky',
}: Props) => {
  const rootGridContainerClassNames = classNames(styles.rootGridContainer, {
    [styles.checkout]: theme === 'checkout',
  })

  const headerContainerClassNames = classNames(styles.headerContainer, {
    [styles.sticky]: headerPosition === 'sticky',
  })

  const mainContainerClassNames = classNames(styles.mainContainer, {
    [styles.widthDefault]: contentWidth === 'default',
  })

  return (
    <div className={rootGridContainerClassNames}>
      <div className={headerContainerClassNames}>
        <Head>
          <title>Routes by Vinted Go</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="facebook-domain-verification" content="gpke9c48ouiuk7w7yv4prmaec08287" />

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Routes by Vinted Go" />
          <meta
            name="description"
            content="Spend less and show the climate more care with Routes by Vinted Go shipping."
          />
          <meta
            property="og:description"
            content="Spend less and show the climate more care with Routes by Vinted Go shipping."
          />

          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Header theme={theme} />
      </div>
      <div className={styles.contentContainer}>
        <main className={mainContainerClassNames}>
          <CookieBanner />
          {children}
        </main>
      </div>
      <Footer theme={theme} />
      <FeedbackBanner />
    </div>
  )
}

export default Layout
