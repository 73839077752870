export const compact = <T>(array: Array<T> | null | undefined): Array<T> => {
  if (!array?.length) return []

  return array.filter(element => !!element)
}

export const uniq = <T>(array: Array<T> | null | undefined): Array<T> => {
  if (!array?.length) return []

  const elementsAsStrings = array.map(element => JSON.stringify(element))
  const uniqueElementStrings = Array.from(new Set(elementsAsStrings))
  const uniqueElements = uniqueElementStrings.map(elementString => JSON.parse(elementString))

  return uniqueElements
}
