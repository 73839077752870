import { Cell, Text } from '@vinted/web-ui'

import { CookieInfo } from '../types'

import styles from './CookieTableRow.module.css'

type Props = {
  isHeader?: boolean
  rowContent: CookieInfo
}

const CookieTableRow = ({ rowContent, isHeader = false }: Props) => {
  return (
    <div className={styles.cookieTableRowContainer}>
      <Cell title={<Text bold={isHeader} text={rowContent.name} />} styling={Cell.Styling.Narrow} />
      <Cell
        title={<Text bold={isHeader} text={rowContent.duration} />}
        styling={Cell.Styling.Narrow}
      />
      <Cell
        title={<Text bold={isHeader} text={rowContent.description} />}
        styling={Cell.Styling.Narrow}
      />
    </div>
  )
}

export default CookieTableRow
