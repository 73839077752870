import { Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import { Cell, Icon, Text, Spacer, Divider, Accordion } from '@vinted/web-ui'
import { ChevronUp24, ChevronDown24 } from '@vinted/monochrome-icons'

import SeparatedList from 'components/SeparatedList'

import CookieTableRow from './CookieTableRow'
import { CookieInfo } from './types'

type Props = {
  cookieType: 'strictlyNecessary' | 'analytical'
  suffix: JSX.Element | string | null
}

const CookieTable = ({ suffix, cookieType }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'cookieSettingsModal.content' })

  const title = t(`cookieTypes.${cookieType}.title`)
  const titleForId = title.replaceAll(' ', '_').toLowerCase()

  const cookiesInfo: Array<CookieInfo> = t(`cookieTypes.${cookieType}.cookies`, {
    returnObjects: true,
  })

  return (
    <Accordion
      title={title}
      closedSuffix={suffix}
      expandedSuffix={suffix}
      closedPrefix={
        <Icon
          name={ChevronDown24}
          display={Icon.Display.Block}
          color={Icon.Color.GreyscaleLevel4}
        />
      }
      expandedPrefix={
        <Icon name={ChevronUp24} display={Icon.Display.Block} color={Icon.Color.GreyscaleLevel4} />
      }
      content={
        <Cell>
          <Text text={t(`cookieTypes.${cookieType}.description`)} />
          <Spacer size={Spacer.Size.Large} />
          <SeparatedList separateLast separator={<Divider />}>
            <Fragment key="cookie_table_header">
              <CookieTableRow isHeader rowContent={t('tableColumns', { returnObjects: true })} />
            </Fragment>
            {cookiesInfo.map((cookieInfo, index) => (
              <Fragment key={`cookie_table_row_${cookieInfo.name + index}`}>
                <CookieTableRow rowContent={cookieInfo} />
              </Fragment>
            ))}
          </SeparatedList>
        </Cell>
      }
      bodyId={`cookie_table_body_${titleForId}`}
      headerId={`cookie_table_header_${titleForId}`}
    />
  )
}

export default CookieTable
