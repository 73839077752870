import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Cell, Divider, Navigation, Spacer, Text, Toggle } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { useCookies } from 'lib/context/cookie'

import Modal from 'components/Modal'
import ScrollableArea from 'components/ScrollableArea'
import SeparatedList from 'components/SeparatedList'

import CookieTable from './CookieTable'

import styles from './CookieSettingsModal.module.css'

type Props = {
  show: boolean
  onClose: () => void
}

const CookieSettingsModal = ({ show, onClose }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'cookieSettingsModal' })
  const {
    cookies: { generalConsent },
    actions: { acceptCookies, rejectCookies },
  } = useCookies()

  const [isAnalyticalCookiesEnabled, setIsAnalyticalCookiesEnabled] = useState(
    generalConsent !== 'denied',
  )

  const handleToggleAnalyticalCookies = () => {
    setIsAnalyticalCookiesEnabled(prevIsEnabled => !prevIsEnabled)
  }

  const handleSaveAndAcceptCookies = () => {
    if (isAnalyticalCookiesEnabled) {
      acceptCookies()
    } else {
      rejectCookies()
    }

    onClose()
  }

  return (
    <Modal
      show={show}
      className={styles.cookieSettingsModal}
      closeOnOverlay
      fullscreenOnMobiles
      hasScrollableContent
      defaultCallback={onClose}
      testId="cookie-settings-modal"
    >
      <div className={styles.body}>
        <Cell styling={Cell.Styling.Wide}>
          <Navigation
            left={<Text text={t('title')} type={Text.Type.HeadingXL} />}
            right={
              <Button
                inline
                iconName={X24}
                styling={Button.Styling.Flat}
                testId="cookie-settings-modal--close-button"
                onClick={onClose}
              />
            }
          />
        </Cell>
        <div className={styles.content}>
          <ScrollableArea maxHeight="100%">
            <Cell styling={Cell.Styling.Wide}>
              <Spacer size={Spacer.Size.XLarge} />
              <Text html text={t('content.general')} />
              <Spacer size={Spacer.Size.XLarge} />
              <Divider />
              <SeparatedList separateLast separator={<Divider />}>
                <CookieTable
                  cookieType="strictlyNecessary"
                  suffix={t('content.cookieTypes.strictlyNecessary.state')}
                />
                <CookieTable
                  cookieType="analytical"
                  suffix={
                    <Cell
                      theme="transparent"
                      styling={Cell.Styling.Tight}
                      body={
                        <Text
                          inline
                          text={
                            isAnalyticalCookiesEnabled
                              ? t('content.cookieTypes.analytical.state.enabled')
                              : t('content.cookieTypes.analytical.state.disabled')
                          }
                        />
                      }
                      suffix={
                        <Toggle
                          checked={isAnalyticalCookiesEnabled}
                          name="cookie_table_analytical_cookies_toggle"
                          testId="cookie-table-analytical-cookies-toggle"
                          onChange={handleToggleAnalyticalCookies}
                        />
                      }
                    />
                  }
                />
              </SeparatedList>
            </Cell>
          </ScrollableArea>
          <Cell
            suffix={
              <Button
                theme="primary"
                styling={Button.Styling.Filled}
                text={t('actions.saveAndAccept')}
                testId="cookie-settings-modal--save-and-accept-button"
                onClick={handleSaveAndAcceptCookies}
              />
            }
          />
        </div>
      </div>
    </Modal>
  )
}

export default CookieSettingsModal
