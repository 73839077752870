import { Fragment, ReactComponentElement, ReactNode, useMemo } from 'react'
import { Divider, Spacer } from '@vinted/web-ui'

import { compact } from 'lib/utils/array'
import isEmptyFragment from 'lib/react/isEmptyFragment'

type Props = {
  children: Array<ReactNode>
  separator?: ReactComponentElement<typeof Divider | typeof Spacer>
  separateLast?: boolean
}

const SeparatedList = ({ children, separator = <Divider />, separateLast = false }: Props) => {
  const items = useMemo(
    () => compact(children.flat()).filter(child => !isEmptyFragment(child)),
    [children],
  )

  if (!items.length) return null

  return (
    <>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item}
          {items.length - 1 !== index || separateLast ? separator : null}
        </Fragment>
      ))}
    </>
  )
}

export default SeparatedList
