import getConfig from 'next/config'

// TODO: refine the way production env is resolved. Potential solution - use special env variable for runtime env.
export const isProduction = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { publicRuntimeConfig } = getConfig()
  const svcShippingUrl = publicRuntimeConfig?.SVC_SHIPPING_URL as string | undefined

  return !svcShippingUrl?.includes('sandbox') || false
}

export const isServerSide = () => typeof window === 'undefined'
