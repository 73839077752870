import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import classNames from 'classnames'

import { Spacer, Text } from '@vinted/web-ui'

import { LayoutTheme } from 'lib/types/layout'
import useVintedGoCorporatePageUrl from 'lib/hooks/useVintedGoCorporatePageUrl'

import styles from './Footer.module.css'

type Props = {
  theme?: LayoutTheme
}

const Footer = ({ theme = 'default' }: Props) => {
  const { t } = useTranslation('common')
  const privacyPolicyUrl = useVintedGoCorporatePageUrl('/privacy-policy')

  const renderLink = (text: string, href: string) => (
    <Link href={href} target="_blank" rel="noopener noreferrer" prefetch={false}>
      <Text underline theme="primary" text={text} />
    </Link>
  )

  return (
    <footer className={classNames(styles.footer, { [styles.checkout]: theme === 'checkout' })}>
      <div className={styles.wrapper}>
        <Text text="© 2024 Routes by Vinted Go" theme="amplified" />
        <div className={styles.linkList}>
          {renderLink(t('footer.privacyAndCookiesPolicy'), privacyPolicyUrl)}
          {renderLink(t('footer.termsOfUse'), '/terms-of-use')}
          {renderLink(t('footer.termsOfService'), '/terms-of-service')}
        </div>
      </div>
      <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.XLarge} />
    </footer>
  )
}

export default Footer
