import Image from 'next/image'
import Link from 'next/link'
import classNames from 'classnames'

import { LayoutTheme } from 'lib/types/layout'

import Navigation from 'components/Navigation'

import styles from './Header.module.css'

type Props = {
  theme?: LayoutTheme
}

const Header = ({ theme = 'default' }: Props) => {
  return (
    <header className={classNames(styles.wrapper, { [styles.checkout]: theme === 'checkout' })}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Link href="/" prefetch={false}>
            <Image src="/assets/svg/logo.svg" alt="Routes by Vinted Go" fill />
          </Link>
        </div>
        <Navigation theme={theme} />
      </div>
    </header>
  )
}

export default Header
