/* eslint-disable @typescript-eslint/restrict-template-expressions */

import Script from 'next/script'

import { useCookies } from 'lib/context/cookie'
import { isProduction } from 'lib/utils/environment'

const GoogleTagManager = () => {
  const {
    cookies: { generalConsent },
  } = useCookies()

  if (generalConsent !== 'granted') return null

  const isInternalTraffic = !isProduction()

  return (
    <>
      <Script
        id="google-tag-manager"
        data-testid="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        if (${isInternalTraffic}) {
          gtag('set', {
            traffic_type: 'internal',
          })
        }

        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'granted'
        });

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-T5RPGM6');`,
        }}
      />

      {/* Sets the consent as granted on page reload or move to another page. 
          Otherwise the consent is initialized again as denied. */}
      <Script
        id="google-tag-manager-consent-update"
        data-testid="google-tag-manager-consent-update"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            gtag('consent', 'update', {
              'analytics_storage': 'granted'
            });
          `,
        }}
      />
    </>
  )
}

export default GoogleTagManager
