import { useTranslation } from 'next-i18next'

import { LayoutTheme } from 'lib/types/layout'
import useVintedGoCorporatePageUrl from 'lib/hooks/useVintedGoCorporatePageUrl'

import { NavigationItem } from './types'
import MobileNavigation from './MobileNavigation'
import DesktopNavigation from './DesktopNavigation'

import styles from './Navigation.module.css'

type Props = {
  theme?: LayoutTheme
}

const Navigation = ({ theme = 'default' }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'header' })
  const trackingPageUrl = useVintedGoCorporatePageUrl('/tracking/routes')

  const menuItems: Array<NavigationItem> = [
    {
      name: t('menu.shipping'),
      route: '/shipping',
    },
    {
      name: t('menu.tracking'),
      route: trackingPageUrl,
    },
    {
      name: t('menu.howItWorks'),
      route: '/how-it-works',
    },
    {
      name: t('menu.help'),
      route: '/help',
    },
  ]

  return (
    <nav className={styles.navigation}>
      <div className={styles.menuMobile}>
        <MobileNavigation theme={theme} menuItems={menuItems} />
      </div>
      <div className={styles.menuDesktop}>
        <DesktopNavigation theme={theme} menuItems={menuItems} />
      </div>
    </nav>
  )
}

export default Navigation
