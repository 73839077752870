import { datadogRum } from '@datadog/browser-rum'

import { isProduction, isServerSide } from 'lib/utils/environment'

const setupDatadogRum = () => {
  const env = isProduction() ? 'production' : 'sandbox'
  const version = process.env.NEXT_PUBLIC_RELEASE_VERSION || 'unknown'
  const baseUrl = window.location.origin

  datadogRum.init({
    applicationId: '908f4b2f-fda0-4a34-ac6d-ea1c63c7a026',
    clientToken: 'pub914fdb7210dd1e0f3622af4f94f2690d',
    site: 'datadoghq.eu',
    service: 'svc-label-checkout-ui',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [baseUrl],
    env,
    version,
  })

  datadogRum.startSessionReplayRecording()
}

const setupDatadogMonitoring = () => {
  if (isServerSide() || window.location.hostname === 'localhost') return

  // Client-side-only code
  setupDatadogRum()
}

export default setupDatadogMonitoring
