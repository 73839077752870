import React from 'react'

import { Text, Cell, Spacer } from '@vinted/web-ui'

import { capitalize } from 'lib/utils/string'
import { useSelectedLanguage } from 'lib/context/language'
import { LanguageOption } from 'lib/context/language/types'
import { LANGUAGE_OPTIONS } from 'lib/context/language/constant'

import styles from './LanguageSwitchMobile.module.css'

const LanguageSwitchMobile = () => {
  const { locales, selectedLanguage, switchToLocale } = useSelectedLanguage()

  const handleOnLanguageClick = (languageOption: LanguageOption) => () => {
    switchToLocale(languageOption)
  }

  return (
    <div>
      <Spacer size={Spacer.Size.Medium} orientation={Spacer.Orientation.Horizontal} />
      <div className={styles.row}>
        {locales.map(locale => {
          const label = LANGUAGE_OPTIONS[locale] || capitalize(locale)
          const isSelected = locale === selectedLanguage.locale

          return (
            <Cell
              key={locale}
              theme="transparent"
              type={Cell.Type.Navigating}
              aria-label={label}
              body={
                <Text
                  bold={isSelected}
                  underline={isSelected}
                  text={locale.toUpperCase()}
                  type={Text.Type.HeadingXL}
                />
              }
              onClick={handleOnLanguageClick({ label, locale })}
            />
          )
        })}
      </div>
    </div>
  )
}

export default LanguageSwitchMobile
