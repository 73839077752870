import { useMemo, useState, useCallback, useContext, ReactNode, createContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { setCookie } from 'cookies-next'

import { capitalize } from 'lib/utils/string'
import { COOKIE_DOMAIN, COOKIE_LOCALE_MAX_AGE, COOKIE_LOCALE_NAME } from 'lib/constants/cookie'

import { LANGUAGE_OPTIONS } from './constant'
import { Locale, LanguageOption } from './types'

type LanguageContextState = {
  locales: Array<Locale>
  selectedLanguage: LanguageOption
  switchToLocale: (event: LanguageOption) => void
}

const INITIAL_LANGUAGE_CONTEXT_STATE: LanguageContextState = {
  locales: [],
  selectedLanguage: {
    locale: 'en',
    label: LANGUAGE_OPTIONS.en,
  },
  /* eslint-disable @typescript-eslint/no-empty-function */
  switchToLocale: () => {},
}

const LanguageContext = createContext<LanguageContextState>(INITIAL_LANGUAGE_CONTEXT_STATE)

const LanguageProvider = (props: { children: ReactNode }) => {
  const router = useRouter()
  const { i18n } = useTranslation()

  const currentLocale = i18n.language as Locale
  const locales = useMemo(
    () => (router.locales ?? [currentLocale]) as Array<Locale>,
    [router.locales, currentLocale],
  )

  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption>({
    locale: currentLocale,
    label: LANGUAGE_OPTIONS[currentLocale] || capitalize(currentLocale),
  })

  const switchToLocale = useCallback(
    ({ locale, label }: LanguageOption) => {
      const path = router.asPath

      setCookie(COOKIE_LOCALE_NAME, locale, {
        path: '/',
        maxAge: COOKIE_LOCALE_MAX_AGE,
        domain: COOKIE_DOMAIN,
      })

      setSelectedLanguage({ locale, label })

      return router.push(path, path, { locale })
    },
    [router],
  )

  const value = useMemo(
    () => ({ locales, selectedLanguage, switchToLocale }),
    [locales, selectedLanguage, switchToLocale],
  )

  return <LanguageContext.Provider value={value}>{props.children}</LanguageContext.Provider>
}

const useSelectedLanguage = () => {
  const { locales, selectedLanguage, switchToLocale } = useContext(LanguageContext)

  return useMemo(
    () => ({ locales, selectedLanguage, switchToLocale }),
    [locales, selectedLanguage, switchToLocale],
  )
}

export { LanguageContext, LanguageProvider, useSelectedLanguage }
