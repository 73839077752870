import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { deleteCookie, getCookie, getCookies, setCookie } from 'cookies-next'

import { COOKIE_CONSENT_MAX_AGE, COOKIE_CONSENT_NAME, COOKIE_DOMAIN } from 'lib/constants/cookie'

import { CookieGeneralConsentState } from './types'
import { getCookieGeneralConsentState } from './utils'

type CookieContextState = {
  cookies: {
    generalConsent: CookieGeneralConsentState
  }
  actions: {
    acceptCookies: () => void
    rejectCookies: () => void
  }
}

const INITIAL_COOKIE_CONTEXT_STATE: CookieContextState = {
  cookies: {
    generalConsent: 'unset',
  },
  actions: {
    /* eslint-disable @typescript-eslint/no-empty-function */
    acceptCookies: () => {},
    /* eslint-disable @typescript-eslint/no-empty-function */
    rejectCookies: () => {},
  },
}

const CookieContext = createContext<CookieContextState>(INITIAL_COOKIE_CONTEXT_STATE)

const CookieProvider = (props: { children: ReactNode }) => {
  const [cookies, setCookies] = useState<CookieContextState['cookies']>({
    generalConsent: getCookieGeneralConsentState(getCookie(COOKIE_CONSENT_NAME)),
  })

  const setGeneralConsent = useCallback((generalConsent: CookieGeneralConsentState) => {
    setCookies({ generalConsent })

    setCookie(COOKIE_CONSENT_NAME, generalConsent, {
      maxAge: COOKIE_CONSENT_MAX_AGE,
      domain: COOKIE_DOMAIN,
    })
  }, [])

  const removeAllCookies = useCallback(() => {
    const cookieNames = Object.keys(getCookies())
    cookieNames.forEach(cookie => deleteCookie(cookie))
  }, [])

  const acceptCookies = useCallback(() => {
    setGeneralConsent('granted')
  }, [setGeneralConsent])

  const rejectCookies = useCallback(() => {
    removeAllCookies()
    setGeneralConsent('denied')
  }, [removeAllCookies, setGeneralConsent])

  const value = useMemo<CookieContextState>(
    () => ({
      cookies,
      actions: {
        acceptCookies,
        rejectCookies,
      },
    }),
    [cookies, acceptCookies, rejectCookies],
  )

  return <CookieContext.Provider value={value}>{props.children}</CookieContext.Provider>
}

const useCookies = () => useContext(CookieContext)

export { CookieProvider, useCookies }
