import * as AppHealth from '@vinted/apphealth-web'

import { isProduction, isServerSide } from 'lib/utils/environment'

const setupAppHealth = () => {
  if (isServerSide() || window.location.hostname === 'localhost') return

  const env = isProduction() ? 'production' : 'sandbox'
  const version = process.env.NEXT_PUBLIC_RELEASE_VERSION || 'unknown'

  AppHealth.watch('svc-label-checkout-ui', {
    context: {
      version,
      env,
    },
  })

  window.apphealth = AppHealth
}

export default setupAppHealth
