import { ReactNode } from 'react'
import { isFragment } from 'react-is'

import { compact } from 'lib/utils/array'

export default function isEmptyFragment(instance: ReactNode) {
  if (!isFragment(instance)) return false

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const children = instance.props?.children

  if (Array.isArray(children)) return compact(children).length === 0

  return !children
}
