import { Modal as ModalWebUI } from '@vinted/web-ui'
import classNames from 'classnames'

import styles from './Modal.module.css'

type Props = {
  fullscreenOnMobiles?: boolean
} & ComponentProps<typeof ModalWebUI>

const Modal = ({ fullscreenOnMobiles = false, ...props }: Props) => {
  return (
    <ModalWebUI
      {...props}
      isFramed={!fullscreenOnMobiles}
      className={classNames(styles.modal, props.className, {
        [styles.fullscreen]: fullscreenOnMobiles,
      })}
    />
  )
}

export default Modal
