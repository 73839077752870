import getConfig from 'next/config'

import { DAYS_PER_YEAR, SECONDS_PER_DAY } from 'lib/constants/date'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { publicRuntimeConfig } = getConfig()

export const COOKIE_LOCALE_NAME = 'NEXT_LOCALE'
export const COOKIE_LOCALE_MAX_AGE = SECONDS_PER_DAY * DAYS_PER_YEAR

export const COOKIE_CONSENT_NAME = 'analytics-consent'
export const COOKIE_CONSENT_MAX_AGE = SECONDS_PER_DAY * DAYS_PER_YEAR

export const COOKIE_SESSION_TOKEN_NAME = 'x-session-token'

export const COOKIE_DOMAIN = publicRuntimeConfig?.NEXT_PUBLIC_COOKIE_DOMAINS as string
