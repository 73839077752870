import { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Icon, Text, Spacer, Button, Divider } from '@vinted/web-ui'
import { Menu24, X24 } from '@vinted/monochrome-icons'

import { LayoutTheme } from 'lib/types/layout'
import { LanguageSwitchMobile } from 'components/LanguageSwitch'

import { NavigationItem } from '../types'

import styles from './MobileNavigation.module.css'

type Props = {
  theme: LayoutTheme
  menuItems: Array<NavigationItem>
}

const MobileNavigation = ({ theme, menuItems }: Props) => {
  const { pathname } = useRouter()
  const { t } = useTranslation('common', { keyPrefix: 'header' })

  const [isOpen, setIsOpen] = useState(false)

  const toggleAriaLabel = isOpen ? t('menuToggle.close') : t('menuToggle.open')

  const handleMenuToggle = () => {
    if (isOpen) {
      setIsOpen(false)
      document.body.classList.remove(styles.noScroll)
    } else {
      setIsOpen(true)
      window.scrollTo(0, 0)
      document.body.classList.add(styles.noScroll)
    }
  }

  return (
    <div className={styles.menuMobile}>
      <Button
        icon={<Icon name={isOpen ? X24 : Menu24} />}
        onClick={handleMenuToggle}
        styling={Button.Styling.Flat}
        aria-label={toggleAriaLabel}
      />
      {isOpen ? (
        <ul className={classNames(styles.menuOverlay, { [styles.checkout]: theme === 'checkout' })}>
          {menuItems.map(({ id, name, route }) => (
            <li id={id} key={name}>
              <Link href={route} prefetch={false} onClick={handleMenuToggle}>
                <Spacer size={Spacer.Size.XLarge} />
                <Text text={name} underline={pathname === route} type={Text.Type.HeadingXL} />
                <Spacer size={Spacer.Size.XLarge} />
                <Divider />
              </Link>
            </li>
          ))}
          <li id="language-switch-mobile" key="language-switch-mobile">
            <LanguageSwitchMobile />
          </li>
        </ul>
      ) : null}
    </div>
  )
}

export default MobileNavigation
