import type { ReactElement, ReactNode } from 'react'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'

import { CookieProvider } from 'lib/context/cookie'
import { TrackingProvider } from 'lib/context/tracking'
import { LanguageProvider } from 'lib/context/language'

import setupAppHealth from 'lib/apphealth'
import setupDatadogMonitoring from 'lib/datadog'
import GoogleTagManager from 'lib/scripts/GoogleTagManagerScript'

import Layout from 'components/Layout'

import 'styles/globals.css'

setupAppHealth()
setupDatadogMonitoring()

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) => {
  const getDefaultLayout = (page: ReactElement) => <Layout>{page}</Layout>
  const getLayout = Component.getLayout || getDefaultLayout

  return (
    <CookieProvider>
      <LanguageProvider>
        <TrackingProvider>
          {getLayout(
            <>
              <GoogleTagManager />
              <Component {...pageProps} />
            </>,
          )}
        </TrackingProvider>
      </LanguageProvider>
    </CookieProvider>
  )
}

export default appWithTranslation(MyApp)
