import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { Cell, Text, Spacer } from '@vinted/web-ui'

import { LayoutTheme } from 'lib/types/layout'
import { LanguageSwitchDesktop } from 'components/LanguageSwitch'
import NavigationActivePageHighlighter from 'components/NavigationActivePageHighlighter'

import { NavigationItem } from '../types'

import styles from './DesktopNavigation.module.css'

type Props = {
  theme: LayoutTheme
  menuItems: Array<NavigationItem>
}

const DesktopNavigation = ({ theme, menuItems }: Props) => {
  const { pathname } = useRouter()

  return (
    <ul className={styles.menuDesktop}>
      {menuItems.map(({ id, name, route }) => (
        <li id={id} key={name}>
          <Link href={route} prefetch={false}>
            <Cell theme="transparent" type={Cell.Type.Navigating}>
              <Text type={Text.Type.Title} text={name} />
            </Cell>
            <span
              className={classNames(styles.activePageHighlighter, {
                [styles.highlighterVisible]: pathname === route,
              })}
            >
              <NavigationActivePageHighlighter />
            </span>
          </Link>
        </li>
      ))}
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.X4Large} />
      <LanguageSwitchDesktop theme={theme} />
    </ul>
  )
}

export default DesktopNavigation
