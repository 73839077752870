import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { Button, Cell, Spacer, Text } from '@vinted/web-ui'

import { useCookies } from 'lib/context/cookie'
import useVintedGoCorporatePageUrl from 'lib/hooks/useVintedGoCorporatePageUrl'

import CookieSettingsModal from './CookieSettingsModal'

import styles from './CookieBanner.module.css'

const CookieBanner = () => {
  const { t } = useTranslation('common')
  const {
    cookies: { generalConsent },
    actions: { acceptCookies, rejectCookies },
  } = useCookies()
  const privacyPolicyUrl = useVintedGoCorporatePageUrl('/privacy-policy')

  const [isBannerVisible, setIsBannerVisible] = useState(false)
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false)

  useEffect(() => {
    setIsBannerVisible(generalConsent === 'unset')
  }, [generalConsent])

  const handleOpenCookieSettingsModal = () => {
    setIsSettingsModalVisible(true)
  }

  const handleCloseCookieSettingsModal = () => {
    setIsSettingsModalVisible(false)
  }

  if (!isBannerVisible) return null

  return (
    <>
      <div className={styles.cookieBannerOverlay}>
        <div className={styles.cookieBannerContainer}>
          <Cell testId="cookie-banner" styling={Cell.Styling.Tight}>
            <div className={styles.cookieBannerContent}>
              <Text type={Text.Type.HeadingXL} text={t('cookieBanner.title')} />
              <Spacer size={Spacer.Size.Medium} />
              <div>
                <Text html inline type={Text.Type.Caption} text={t('cookieBanner.content.part1')} />
                <Link
                  href={privacyPolicyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  prefetch={false}
                >
                  <Text
                    inline
                    underline
                    theme="primary"
                    type={Text.Type.Caption}
                    text={t('cookieBanner.content.privacyPolicyChunk')}
                  />
                </Link>
                <Text html inline type={Text.Type.Caption} text={t('cookieBanner.content.part2')} />
              </div>
              <Spacer size={Spacer.Size.XLarge} />
              <div className={styles.cookieBannerActionButtons}>
                <div className={styles.cookieBannerAcceptButton}>
                  <Button
                    theme="primary"
                    styling={Button.Styling.Filled}
                    text={t('cookieBanner.actions.accept')}
                    onClick={acceptCookies}
                  />
                </div>
                <div className={styles.cookieBannerRejectButton}>
                  <Button
                    theme="primary"
                    styling={Button.Styling.Outlined}
                    text={t('cookieBanner.actions.reject')}
                    onClick={rejectCookies}
                  />
                </div>
                <div className={styles.cookieBannerSettingsButton}>
                  <Button
                    theme="primary"
                    styling={Button.Styling.Flat}
                    text={t('cookieBanner.actions.settings')}
                    onClick={handleOpenCookieSettingsModal}
                  />
                </div>
              </div>
            </div>
          </Cell>
        </div>
      </div>
      <CookieSettingsModal show={isSettingsModalVisible} onClose={handleCloseCookieSettingsModal} />
    </>
  )
}

export default CookieBanner
