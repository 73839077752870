import React from 'react'
import classNames from 'classnames'

import { Card, Cell, Icon } from '@vinted/web-ui'
import { Dropdown12 } from '@vinted/monochrome-icons'

import SeparatedList from 'components/SeparatedList'
import NavigationActivePageHighlighter from 'components/NavigationActivePageHighlighter'

import { capitalize } from 'lib/utils/string'
import { LayoutTheme } from 'lib/types/layout'
import { useSelectedLanguage } from 'lib/context/language'
import { LanguageOption } from 'lib/context/language/types'
import { LANGUAGE_OPTIONS } from 'lib/context/language/constant'

import styles from './LanguageSwitchDesktop.module.css'

type Props = {
  theme?: LayoutTheme
}

const LanguageSwitchDesktop = ({ theme = 'default' }: Props) => {
  const { locales, selectedLanguage, switchToLocale } = useSelectedLanguage()

  const handleOnLanguageClick = (languageOption: LanguageOption) => () => {
    switchToLocale(languageOption)
  }

  return (
    <div className={styles.dropdown}>
      <div className={styles.title}>
        <Cell
          theme="transparent"
          type={Cell.Type.Navigating}
          title={<p className={styles.title}>{capitalize(selectedLanguage.locale)}</p>}
          suffix={<Icon name={Dropdown12} color={Icon.Color.GreyscaleLevel1} />}
          testId="language-switch-desktop--selected-language"
        />
        <span className={styles.activePageHighlighter}>
          <NavigationActivePageHighlighter />
        </span>
      </div>
      <div className={styles.dropdownContent}>
        <Card styling={Card.Styling.Lifted} testId="language-switch-desktop--language-list">
          <div className="u-overflow-hidden">
            <SeparatedList>
              {locales.map(locale => {
                const label = LANGUAGE_OPTIONS[locale] || capitalize(locale)

                return (
                  <div
                    key={locale}
                    className={classNames(styles.dropdownContentItem, {
                      [styles.checkout]: theme === 'checkout',
                    })}
                  >
                    <Cell
                      key={locale}
                      theme="transparent"
                      type={Cell.Type.Navigating}
                      title={<p className={styles.title}>{label}</p>}
                      onClick={handleOnLanguageClick({ locale, label })}
                    />
                  </div>
                )
              })}
            </SeparatedList>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default LanguageSwitchDesktop
